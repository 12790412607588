import React from 'react';
import PropTypes from 'prop-types';

import Columns from '../main/Columns';
import Layout from './Layout';


export const SpecialLayoutPageTemplate = ({
  contents,
  langKey,
  reusable,
  paddingBottom
}) => (
  <div style={{paddingBottom: paddingBottom}}>
    {/* Content */}
    {contents !== null &&
     contents.map(content => (
      <>
        {/* Check if full width */}
        {content.isFullWidth ? (
          <div style={{paddingBottom: "1rem"}}>
            <Columns
              content={content}
              langKey={langKey}
              reusable={reusable}
            />
          </div>
        ) : (
          <section className="section section--gradient" style={{padding: "2rem 1rem"}}>
            <div className="container" style={{paddingLeft: "2rem", paddingRight: "2rem"}}>
              <div className="columns">
                <div className="column is-12">
                  <Columns
                    content={content}
                    langKey={langKey}
                    reusable={reusable}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    ))}
  </div>
)

SpecialLayoutPageTemplate.propTypes = {
  contents: PropTypes.object,
  langKey: PropTypes.string,
  reusable: PropTypes.object,
  paddingBottom: PropTypes.string,
}

const SpecialLayoutPage = ({
  data: {
    page: {
      fields: { langKey, pathSlug },
      frontmatter: { contents },
    },
    reusable: { edges: reuse },
  },
  paddingBottom
}) => {
  return (
    <Layout
      langKey={langKey}
      slug={pathSlug}
    >
      <SpecialLayoutPageTemplate
        contents={contents}
        langKey={langKey}
        reusable={reuse}
        paddingBottom={paddingBottom}
      />
    </Layout>
  )
}

SpecialLayoutPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    reusable: PropTypes.object,
  }),
  paddingBottom: PropTypes.string,
}

export default SpecialLayoutPage